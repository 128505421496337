import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import { PlaceholderType } from 'src/utilities/image-helpers';
import { cloudinaryLoaderSquare } from 'src/utilities/image-sdk';

type ImageSquareProps = Omit<ImageProps, 'src' | 'placeholder'> & {
  /**
   * The image source URL (e.g. https://res.cloudinary.com/...)
   * If `src` is not defined, the component will fall back to a placeholder image
   */
  src: string | undefined;
  /**
   * The type of placeholder to render if `src` is not defined
   * @default 'default'
   * @see PlaceholderType
   */
  placeholder?: PlaceholderType;
};

/**
 * An ImageSquare displays an image in the 1:1 format aka the square format.
 * When `src` is not defined it will fall back to a placeholder image.
 *
 * @param props ImageSquareProps
 * @example <ImageSquare src={image.url} alt={title} />;
 */
export default function ImageSquare({ src, alt, placeholder = 'default', className, ...imageProps }: ImageSquareProps) {
  return (
    <div
      className={clsx(
        'relative aspect-square bg-deepBlueC4',
        // Placeholder image as pseudo `:before` element
        `before:absolute before:inset-0 before:bg-contain before:bg-center before:bg-no-repeat`,
        placeholder === 'default' && "before:bg-[url('/images/placeholders/default.svg')]",
        placeholder === 'artist' && "before:bg-[url('/images/placeholders/artist.svg')]",
        placeholder === 'group' && "before:bg-[url('/images/placeholders/group.svg')]",
        placeholder === 'partner' && "before:bg-[url('/images/placeholders/partner.svg')]",
        className,
      )}
    >
      {/* Image needs position relative so it is always over the placeholder */}
      {src && <Image loader={cloudinaryLoaderSquare} src={src} className="relative" alt={alt} {...imageProps} />}
    </div>
  );
}
