import useSWR from 'swr';
import useSdk from 'src/hooks/use-sdk';

import { CurrencyCode, getValidCurrencyCode } from 'src/utilities/currency-code-helpers';

type GeoInfo =
  | {
      countryCode: string;
      currencyCode: CurrencyCode;
    }
  | undefined;

async function fetchGeoInfo(sdk: ReturnType<typeof useSdk>): Promise<GeoInfo> {
  const { geoinfo } = await sdk.geoinfo();

  if (!geoinfo) return;

  return {
    // Make sure the country code is always a valid ISO 3166-1 alpha-2 code (uppercase) e.g. 'DE'
    countryCode: geoinfo.countryCode.toUpperCase(),
    // Make sure the currency code is always a valid currency code
    currencyCode: getValidCurrencyCode(geoinfo.currencyCode),
  };
}

/**
 * Retrieves the visitor’s country code and currency code via the `geoinfo` API.
 * Both codes are determined by the visitor’s IP address.
 * Since this data is not likely to change very often during a session, we only invalidate the cache on network reconnection.
 *
 * @returns An SWR response with the visitor’s country code (ISO 3166-1 alpha-2) and currency code
 * @example
 * ```tsx
 * const { data, error } = useGeoInfo();
 * // data === { countryCode: 'DE', currencyCode: 'EUR' }
 * ```
 */
export default function useGeoInfo() {
  const sdk = useSdk();
  return useSWR('geoinfo', () => fetchGeoInfo(sdk), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: true,
  });
}
