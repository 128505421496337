import { useRef, useEffect, useState, type ComponentProps } from 'react';
import Link from 'next/link';

type InViewLinkProps = ComponentProps<typeof Link> & {
  observerOptions?: IntersectionObserverInit;
};

// Use `undefined` instead of 0 to avoid generating HTML for default values (0)
const TAB_INDEX_ACTIVE = undefined;
const TAB_INDEX_INACTIVE = -1;

/**
 * Extends the basic Link component by adding `tabIndex={-1}` when the link is
 * out of the viewport to prevent tabbing on it.
 *
 * Useful for sliders and such when users my tab into an offscreen link.
 */
export default function InViewLink({ observerOptions, ...props }: InViewLinkProps) {
  const ref = useRef<HTMLAnchorElement>(null);
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);

  useEffect(() => {
    if (ref.current === null || window.IntersectionObserver === undefined) {
      return;
    }

    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry?.isIntersecting);
    }, observerOptions);

    observer.observe(ref.current);

    return () => observer.disconnect();
  }, [observerOptions]);

  return <Link {...props} ref={ref} tabIndex={isIntersecting ? TAB_INDEX_ACTIVE : TAB_INDEX_INACTIVE} />;
}
