import clsx from 'clsx';
import Image, { ImageProps } from 'next/image';
import { cloudinaryLoaderWide } from 'src/utilities/image-sdk';

type ImageWideProps = Omit<ImageProps, 'src'> & {
  src: string | undefined;
};

/**
 * An ImageWide displays an image in the 16:9 format aka the wide format.
 * When `src` is not defined it will fall back to a placeholder image.
 *
 * @param props ImageWideProps
 * @example <ImageWide src={image.url} alt={title} />;
 */
export function ImageWide({ src, className, alt, ...imageProps }: ImageWideProps) {
  return (
    <div
      className={clsx(
        'pointer-events-none relative aspect-video select-none bg-deepBlueC4',
        // Placeholder image as pseudo `:before` element
        "before:absolute before:inset-0 before:bg-[url('/images/placeholders/default-wide.svg')] before:bg-contain before:bg-center before:bg-no-repeat",
        className,
      )}
    >
      {/* Image needs position relative so it is always over the placeholder */}
      {src && <Image src={src} alt={alt} loader={cloudinaryLoaderWide} className="relative" {...imageProps} />}
    </div>
  );
}
