import { Fragment } from 'react';
import { LiveConcert } from 'generated/graphql';
import RenderClientOnly from 'src/components/render-client-only';
import useCountdown from 'src/hooks/use-countdown';
import useLiveConcertState from 'src/hooks/use-live-concert-state';
import useTranslate from 'src/hooks/use-translate';
import { TranslationKeyCommon } from 'src/types';
import { secondsToDuration, Duration } from 'src/utilities/date-helpers';

type LiveConcertCountdownProps = {
  liveConcert: Pick<LiveConcert, 'id' | 'startTime' | 'endTime' | 'streamStartTime' | 'reruns'>;
};

const durationUnitLabels: Record<keyof Duration, TranslationKeyCommon> = {
  days: 'component__countdown_days_unit',
  hours: 'component__countdown_hours_unit',
  minutes: 'component__countdown_minutes_unit',
  seconds: 'component__countdown_seconds_unit',
};

/**
 * Renders a countdown component based on a startTime
 */
function Countdown({ startTime }: { startTime: string }) {
  const t = useTranslate();
  const secondsUntilStart = useCountdown(startTime);
  const duration = secondsToDuration(secondsUntilStart);
  const units = [
    { value: duration.days, label: t(durationUnitLabels.days) },
    { value: duration.hours, label: t(durationUnitLabels.hours) },
    { value: duration.minutes, label: t(durationUnitLabels.minutes) },
    { value: duration.seconds, label: t(durationUnitLabels.seconds) },
  ];

  return (
    <div className="inline-flex select-none rounded bg-deepBlueC4/70" data-test="live-concert-countdown">
      <div className="flex items-center px-4 py-2">
        {/* “On Air In” */}
        <span className="dg-text-regular-4-uc mr-3 inline-block shrink-0 md:mr-4">
          {t('component__countdown_title')}
        </span>
        {units.map((unit, index) => (
          <div key={index} className="flex items-baseline">
            {/* divider */}
            {index > 0 && <span className="mx-1.5 inline-block h-4 self-center border-r opacity-50 xl:h-5" />}
            {/* unit */}
            <span className="dg-text-regular-3-uc mr-0.5 w-4 text-right leading-none text-brandYellowC1 md:w-5 xl:w-6">
              {unit.value}
            </span>
            {/* value */}
            <span className="dg-text-regular-4-uc whitespace-nowrap opacity-70">{unit.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

/**
 * A LiveConcertCountdown displays a countdown clock for a LiveConcert’s startTime.
 * It will only be shown when the concert hasn’t started yet.
 *
 * @example <LiveConcertCountdown liveConcert={liveConcert} />;
 */
export default function LiveConcertCountdown({ liveConcert }: LiveConcertCountdownProps) {
  const { status, startTime, streamStartTime } = useLiveConcertState(liveConcert);
  const showCountdown = status === 'upcoming';
  const liveConcertStartTime = streamStartTime || startTime || liveConcert.streamStartTime || liveConcert.startTime;

  return (
    <RenderClientOnly>
      {/* date will be different between server and client so only render on client */}
      {showCountdown ? (
        // We’re not rendering the countdown element inline because the countdown relies on a
        // setInterval to compare the current time to the startTime. Rendering this as a separate
        // component allows us to call the `useCountdown` hook only when the countdown is needed.
        // Basically it’s a performance micro-optimization to avoid an unnecessary setInterval.
        <Countdown startTime={liveConcertStartTime} />
      ) : (
        <Fragment />
      )}
    </RenderClientOnly>
  );
}
