import { type HTMLAttributes, useState, useCallback, Fragment } from 'react';
import clsx from 'clsx';
import { PictureType, PictureFieldsFragment } from 'generated/graphql';
import { ImageWide } from 'src/components/common/image-wide';
import ContainerGrid from 'src/components/container-grid';
import LiveConcertCountdown from 'src/components/live-concert/live-concert-countdown';
import RenderClientOnly from 'src/components/render-client-only';
import useInterval from 'src/hooks/use-interval';
import useTranslate from 'src/hooks/use-translate';
import { LandingPageLiveConcert } from 'src/types';
import { getPictureByType } from 'src/utilities/image-helpers';
import { cloudinaryLoaderWide } from 'src/utilities/image-sdk';

/**
 * The live feature section of the landing page.
 * Displays the next upcoming live concert, a countdown clock, image, and a button to sign up.
 */
function LandingPageLive({
  liveConcerts,
  ...rootHtmlElementAttributes
}: HTMLAttributes<HTMLDivElement> & { liveConcerts: LandingPageLiveConcert[] }) {
  const t = useTranslate();
  const [upcomingLiveConcert, setUpcomingLiveConcert] = useState<LandingPageLiveConcert>();
  const upcomingLiveConcertPicture =
    upcomingLiveConcert?.pictures && getPictureByType(upcomingLiveConcert.pictures, PictureType.Teaser);

  // Find the next upcoming live concert and set it as the state
  const updateUpcomingLiveConcert = useCallback(() => {
    const now = Date.now();
    const upcomingLiveConcert = liveConcerts.find((liveConcert) => {
      const liveConcertDate = new Date(liveConcert.streamStartTime || liveConcert.startTime);
      return liveConcertDate.getTime() > now;
    });
    setUpcomingLiveConcert(upcomingLiveConcert);
  }, [liveConcerts]);

  // Update the upcoming live concert every second
  useInterval(updateUpcomingLiveConcert, 1000);

  return (
    // Render client-side only because the server and client may have different times
    <RenderClientOnly>
      {upcomingLiveConcert ? (
        <div data-test="landing-page-live-module" {...rootHtmlElementAttributes}>
          <ContainerGrid>
            <LandingPageLiveModulePicture
              upcomingLiveConcert={upcomingLiveConcert}
              upcomingLiveConcertPicture={upcomingLiveConcertPicture}
              className="hidden lg:block"
              role="presentation"
            />
            <div className="col-span-full self-center text-center lg:col-span-6">
              <h2 className="dg-text-medium-2 mb-8 lg:mb-10">{t('page__landing_live_headline')}</h2>
              <LandingPageLiveModulePicture
                upcomingLiveConcert={upcomingLiveConcert}
                upcomingLiveConcertPicture={upcomingLiveConcertPicture}
                className="lg:hidden"
              />
              <div className="mb-3 flex justify-center md:mb-5">
                <LiveConcertCountdown liveConcert={upcomingLiveConcert} />
              </div>
              <p className="dg-text-light-1 mb-6 lg:mb-8 2xl:mb-12">{upcomingLiveConcert.title}</p>
            </div>
          </ContainerGrid>
        </div>
      ) : (
        <Fragment />
      )}
    </RenderClientOnly>
  );
}

function LandingPageLiveModulePicture({
  upcomingLiveConcertPicture,
  upcomingLiveConcert,
  role,
  className,
}: {
  upcomingLiveConcertPicture: PictureFieldsFragment | undefined;
  upcomingLiveConcert: LandingPageLiveConcert;
  role?: string;
  className?: string;
}) {
  return (
    <div
      className={clsx(
        'relative col-span-full mb-8 overflow-hidden rounded lg:order-2 lg:col-span-6 lg:mb-0',
        className,
      )}
      role={role}
    >
      {upcomingLiveConcertPicture && (
        <ImageWide
          alt={upcomingLiveConcert.title}
          src={upcomingLiveConcertPicture.url}
          loader={cloudinaryLoaderWide}
          // suggested srcSet for the image based on sizes defined in the imageSizes|deviceSizes array in next.config.js
          sizes="(min-width: 1280px) 1080px, (min-width: 1024px) 512px, 100vw"
          fill
        />
      )}
    </div>
  );
}

export default LandingPageLive;
