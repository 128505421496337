import { useEffect, useState } from 'react';
import { differenceInSeconds } from 'src/utilities/date-helpers';

/**
 * Returns the number of seconds between a given date and now. Will update every
 * second. The number returned can be negative.
 */
export default function useCountdown(date: Date | string): number {
  const [value, setValue] = useState<number>(differenceInSeconds(new Date(date)));

  useEffect(() => {
    const tick = setInterval(() => setValue(differenceInSeconds(new Date(date))), 1000);
    return () => {
      clearInterval(tick);
    };
  }, [date, setValue]);

  return value;
}
