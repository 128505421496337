import { useState, useEffect, RefObject } from 'react';

const isSSR = typeof window === 'undefined';

/**
 * A hook to detect if the window is scrolled beyond a certain threshold
 * @param thresholdY - pixel from the window top to activate the callback on
 * @returns
 */
const useDidScrollBeyond = (thresholdY: number): boolean => {
  const [didScrollBeyond, setDidScrollBeyond] = useState(!isSSR && window.scrollY > thresholdY);

  const scrollHandler = () => window.requestAnimationFrame(() => setDidScrollBeyond(window.scrollY > thresholdY));

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  });

  return isSSR ? false : didScrollBeyond;
};

/**
 * A hook to detect if the window is scrolled vertically beyond a certain DOM element
 * @param ref - The ref to the element to activate the callback when element is scrolled beyond
 */
export const useDidScrollBeyondRef = (ref: RefObject<HTMLElement>) => useDidScrollBeyond(ref.current?.offsetTop ?? 0);

export default useDidScrollBeyond;
