import Image from 'next/image';
import Link from 'next/link';
import { Banner, PictureType } from 'generated/graphql';
import ContainerGrid from 'src/components/container-grid';
import { useImpressionTracking } from 'src/tracking';
import { getPictureByType } from 'src/utilities/image-helpers';
import { cloudinaryLoader } from 'src/utilities/image-sdk';

type ContentContainerBannerProps = {
  banner: Banner;
};

/**
 * React component displaying a `Banner` ContentContainer datatype.
 * Always implemented by a `ContentContainers` component among other
 * `ContentContainerBanner` and `ContentContainerSlider` children.
 * When `banner.pictures` are not defined it will fall back to a background color.
 *
 * @param banner Banner ContentContainer datatype
 * @example <ContentContainerBanner banner={contentContainerItem} />;
 */
export default function ContentContainerBanner({ banner }: ContentContainerBannerProps) {
  const { link, title, pictures } = banner;
  const image = getPictureByType(pictures, PictureType.Banner)?.url;
  const { trackEvent, impressionObserverRef } = useImpressionTracking(
    { eventName: 'EECpromotionImpression', banner },
    {
      trackImpressionOnlyOnce: false, // track banner impressions every time it appears on the screen
    },
  );
  return (
    <div className="py-10">
      <ContainerGrid>
        <Link
          href={link}
          className="col-span-full block overflow-hidden rounded md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3"
          onClick={() => trackEvent({ eventName: 'EECpromotionClick', banner })}
          data-test="banner"
          // open the link in a new tab
          target="_blank"
        >
          <div className="relative aspect-[16/4] bg-formBlueC5" ref={impressionObserverRef}>
            <div className="absolute inset-0">
              {image && <Image fill loader={cloudinaryLoader} src={image} placeholder="empty" alt={title || ''} />}
            </div>
          </div>
        </Link>
      </ContainerGrid>
    </div>
  );
}
