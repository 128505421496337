import { useRef, useEffect, PropsWithChildren, HTMLProps, Children, forwardRef, type Ref } from 'react';
import clsx from 'clsx';
import useReducedMotion from 'src/hooks/use-reduced-motion';

type CarouselProps = PropsWithChildren<HTMLProps<HTMLDivElement>> & {
  /** Class names for the overall container */
  mainContainerClassName?: string;
  /** Class names for the container that animates */
  itemsContainerClassName?: string;
  /** The time it takes for the first slide to animate out of the screen */
  itemSlideDuration: number;
  /** Pass `false` to disable animation */
  animate?: boolean;
};

/**
 * Carousel component renders a horizontal list of items, animated infinitely
 * from right to left. On hover, the animation stops.
 *
 * `<Carousel>` component wraps its children in a div, that is animated from
 * x:0 to x:-50%, and that is wrapped in a full-width overflow hidden component.
 * The children are rendered twice, one after another, to simulate "circular"
 * scrolling.
 *
 * The horizontal layout of children is based on flex layout, so the wrappers
 * are setting the respective flex properties.
 **/
export const Carousel = forwardRef(function Carousel(
  {
    mainContainerClassName,
    itemsContainerClassName,
    itemSlideDuration,
    animate = true,
    children,
    ...rest
  }: CarouselProps,
  ref: Ref<HTMLDivElement>,
) {
  const prefersReducedMotion = useReducedMotion();

  // Duration of the whole animation cycle: duration of one slide * 2x children,
  // since we render each child twice
  const duration = itemSlideDuration * Children.count(children) * 2;

  const animatedRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<Animation>();

  const pauseAnimation = () => {
    animationRef?.current?.pause();
  };
  const resumeAnimation = () => {
    animationRef?.current?.play();
  };

  useEffect(() => {
    if (prefersReducedMotion || animationRef.current || animate === false) {
      return;
    }

    animationRef.current = animatedRef.current?.animate(
      [
        {
          transform: 'translateX(0)',
        },
        {
          transform: 'translateX(-50%)',
        },
      ],
      {
        /* the time it takes for one slide to move out of the screen, multiplied by the number of items*/
        duration,
        iterations: Number.POSITIVE_INFINITY,
      },
    );

    function onDocumentVisibilityChange() {
      if (document.hidden) {
        pauseAnimation();
      } else {
        resumeAnimation();
      }
    }

    document.addEventListener('visibilitychange', onDocumentVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', onDocumentVisibilityChange);
    };
  }, [prefersReducedMotion, duration, animate]);

  return (
    <div className={clsx('flex overflow-hidden', mainContainerClassName)} ref={ref} {...rest}>
      <div
        className={clsx(
          'flex shrink-0 grow-0 will-change-transform motion-reduce:-translate-x-12',
          itemsContainerClassName,
        )}
        ref={animatedRef}
        onMouseEnter={pauseAnimation}
        onMouseLeave={resumeAnimation}
        onFocus={pauseAnimation}
        onBlur={resumeAnimation}
      >
        {/* Render children twice to create effect of continuous animation */}
        <div className="flex shrink-0 grow-0">{children}</div>
        <div className="flex shrink-0 grow-0">{children}</div>
      </div>
    </div>
  );
});
