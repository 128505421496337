import { useRef } from 'react';
import Image from 'next/image';
import dgLogoSource from 'public/images/dg-logo.png';
import ContainerGrid from 'src/components/container-grid';
import { LandingPageBackgroundVideo } from 'src/components/landing-page/landing-page-video';
import ScrollDownHint from 'src/components/scroll-down-hint';
import SignupCtaButton from 'src/components/signup-cta-button';
import useTranslate from 'src/hooks/use-translate';
import { TrackingContext, TrackingContextValues } from 'src/tracking/tracking-context';

/**
 * Full width hero with video and registration button
 * Originally built for the 2022 launch
 */
function LandingPageHero2022() {
  const t = useTranslate();

  // The scroll marker is used to mark the start of the content that is below
  // the header
  const scrollMarkerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="main-area relative flex flex-col justify-between" style={{ minHeight: '32rem' }}>
        <LandingPageBackgroundVideo />
        <ContainerGrid>
          <div className="relative col-span-full">
            <div className="absolute left-0 top-12 h-[67px] w-[120px] lg:h-[101px] lg:w-[184px]">
              <Image alt={t('page__landing_hero_logo_label')} src={dgLogoSource} unoptimized fill />
            </div>
          </div>
        </ContainerGrid>
        <ContainerGrid>
          <div className="relative col-span-full py-24 text-center lg:mb-[3vw] xl:py-28">
            <h1 className="dg-text-medium-1 mb-2 2xl:mb-4">{t('page__landing_hero_headline')}</h1>
            <p className="dg-text-regular-5 mb-4 opacity-70 2xl:mb-8">{t('page__landing_hero_description')}</p>
            <TrackingContext.Provider value={TrackingContextValues.LandingPageHero}>
              <SignupCtaButton />
            </TrackingContext.Provider>
          </div>
        </ContainerGrid>
        <div className="absolute bottom-2 left-1/2 -ml-4">
          <ScrollDownHint refToScrollTo={scrollMarkerRef} />
        </div>
      </div>
      {/* A click on ScrollDownHint will scroll this element to the top of the screen  */}
      <div ref={scrollMarkerRef}></div>
    </>
  );
}

export default LandingPageHero2022;
