import { type ReactNode, useRef } from 'react';
import { PictureType } from 'generated/graphql';
import { Carousel } from 'src/components/carousel';
import InViewLink from 'src/components/in-view-link';
import { Teaser } from 'src/components/teaser/teaser';
import { TeaserImage } from 'src/components/teaser/teaser-image';
import useTranslate from 'src/hooks/use-translate';
import type { LandingPageNewAlbums, LandingPagePopularNow } from 'src/types';
import { getPictureByType } from 'src/utilities/image-helpers';
import { getNodePath } from 'src/utilities/url-helpers';

// the time it takes for one slide to travel from right edge to the left one
const popularNowCarouselSlideDuration = 14_000;
const newAlbumsCarouselSlideDuration = 4000;
// Desktop classes for album cover in a slider
const sliderAlbumClassNamesLg = 'lg:mr-[30px] lg:w-[160px]';

/**
 * A link that becomes active when it is inside the slider viewport
 */
function SliderLink({ href, root, children }: { href: string; root: Element | null; children: ReactNode }) {
  return (
    <InViewLink
      href={href}
      prefetch={false}
      className="block rounded-sm outline-offset-1 focus-visible:focus-outline"
      observerOptions={{
        // We want to make the links active only when they are inside the slider
        // viewport, regardless of the page scrolling position
        // 1. Get a handle of a slider container (Carousel component)
        root,
        // 2. Trigger intersection only when at least 75% of a slide item is
        //    within the slider viewport
        threshold: 0.75,
        // 3. Only take into account horizontal overlap, ignore vertical overlap
        rootMargin: '100% 0% 100% 0%',
      }}
    >
      <Teaser>{children}</Teaser>
    </InViewLink>
  );
}

function LandingPageExclusiveContent({
  popularNowItems,
  newAlbumItems,
}: {
  popularNowItems: LandingPagePopularNow[];
  newAlbumItems: LandingPageNewAlbums[];
}) {
  const t = useTranslate();
  const popularNowCarouselRef = useRef<HTMLDivElement>(null);
  const newAlbumsCarouselRef = useRef<HTMLDivElement>(null);
  return (
    <section className="flex flex-col gap-9 pb-20 pt-12" data-test="landing-page-exclusive-content">
      <h2 className="dg-text-medium-2 md:dg-text-medium-1 mx-auto mb-5 max-w-2xl text-center md:mb-4">
        {t('marketing_exlcusive_content__access')}
      </h2>
      <p className="dg-text-medium-3 md:dg-text-regular-5 mx-auto mb-[60px] max-w-2xl text-center font-light opacity-70">
        {t('marketing_exlcusive_content__explore')}
      </p>
      <Carousel
        ref={popularNowCarouselRef}
        // Negative margin + padding to make focus outlines visible inside the slider
        mainContainerClassName="-my-1 py-1"
        itemsContainerClassName="items-top"
        itemSlideDuration={popularNowCarouselSlideDuration}
        data-test="landing-page-popular-now-carousel"
      >
        {popularNowItems.map((item) => (
          <div key={item.id} className="mr-4 w-[190px] lg:mr-10 lg:w-[408px]">
            <SliderLink href={getNodePath(item)} root={popularNowCarouselRef?.current}>
              <div className="mb-2">
                <TeaserImage
                  src={getPictureByType(item.pictures, PictureType.Teaser)?.url}
                  variant="wide"
                  playable
                  darken
                  alt=""
                />
              </div>
              <div className="font-light text-sm opacity-55 lg:text-xl">{item.title}</div>
            </SliderLink>
          </div>
        ))}
      </Carousel>
      <Carousel
        // Negative margin + padding to make focus outlines visible inside the slider
        mainContainerClassName="-my-1 py-1"
        itemsContainerClassName="items-top"
        itemSlideDuration={newAlbumsCarouselSlideDuration}
        data-test="landing-page-new-albums-carousel"
      >
        {newAlbumItems.map((item) => (
          <div className={`mr-4 w-[89px] ${sliderAlbumClassNamesLg}`} key={item.id}>
            <SliderLink href={getNodePath(item)} root={newAlbumsCarouselRef?.current}>
              <TeaserImage
                src={getPictureByType(item.pictures, PictureType.Cover)?.url}
                variant="square"
                playable
                darken
                alt={item.title}
              />
            </SliderLink>
          </div>
        ))}
      </Carousel>
    </section>
  );
}

export default LandingPageExclusiveContent;
