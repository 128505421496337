import type { ProductsStripeQuery } from 'generated/graphql';
import { GiftCardsAboutVinyl } from 'src/components/gift-cards/gift-cards-about-vinyl';
import { GiftCardsPurchaseHero } from 'src/components/gift-cards/gift-cards-purchase-hero';
import { useGiftCards } from 'src/hooks/use-gift-cards';

/**
 * Hero section for the landing page with the Christmas 2024 campaign promo
 */
export default function LandingPageHeroChristmas2024({ products }: { products: ProductsStripeQuery }) {
  const { price, selectedOption, disableAnnualSubscription, onSubmit, onChangeOption } = useGiftCards({ products });

  return (
    <div className="mx-auto flex max-w-[90rem] flex-col gap-y-16 px-5 pt-8 md:px-8 md:pb-8 md:pt-16">
      <GiftCardsPurchaseHero
        price={price}
        disableAnnualSubscription={disableAnnualSubscription}
        selectedOption={selectedOption}
        onChange={onChangeOption}
        onSubmit={onSubmit}
      />
      <GiftCardsAboutVinyl />
    </div>
  );
}
