import { useMemo } from 'react';
import { useCurrentLocale } from 'src/hooks/use-current-locale';
import useGeoInfo from 'src/hooks/use-geo-info';
import { currencyFormat, dateFormat, DateFormatStyle, IntlLocale, listFormat } from 'src/utilities/intl-helpers';

/**
 * React hook around the Intl helper that automatically passes the locale from the router.
 * Currently supports the following methods:
 * - currencyFormat: converts a number to a currency string
 * - listFormat: takes an array of strings and returns string with a localized separator between each item
 *
 * @example
 * ```ts
 * const { currencyFormat, dateFormat, listFormat } = useIntl();
 *
 * // Currency format
 * const amount = 12345;
 * const currency = 'EUR';
 * const currencyString = currencyFormat(amount, currency);
 * // => '€123,45'
 *
 * // Date format
 * const date = new Date();
 * const formattedDate = dateFormat(date, 'short');
 * // => '02/17/2022'
 *
 * // List format
 * const items = ['a', 'b', 'c'];
 * const list = listFormat(items);
 * // 'a, b, c'
 * ```
 */
export default function useIntl() {
  const currentLocale = useCurrentLocale();
  const { data: geoInfo } = useGeoInfo();
  const locale: IntlLocale = geoInfo?.countryCode ? `${currentLocale}-${geoInfo.countryCode}` : currentLocale;

  return useMemo(
    () => ({
      locale,
      /**
       * Converts a number to a localized currency string
       * @see intl-helpers:currencyFormat
       */
      currencyFormat: (amount: number, currency: string) => currencyFormat(amount, currency, locale),
      /**
       * Converts a date to a localized date string
       * @see intl-helpers:dateFormat
       */
      dateFormat: (date: Date, style: DateFormatStyle) => dateFormat(locale, style).format(date),
      /**
       * Takes an array of strings and returns string with a localized separator between each item
       * @see intl-helpers.ts:listFormat
       */
      listFormat: (array: string[]) => listFormat(array, { locale }),
    }),
    [locale],
  );
}
