import SmallChevronIcon from '@stageplus/icons/react/small-chevron';
import clsx from 'clsx';
import { SignupLink } from 'src/components/auth-link';
import useTranslate from 'src/hooks/use-translate';
import { TranslationKeyCommon } from 'src/types';

type SignupCtaButtonProps = {
  /**
   * Optional custom translation key for the button text.
   * @default 'button__signup_cta'
   */
  translationKey?: TranslationKeyCommon;
  /**
   * Make the button occupy all available horizontal space
   */
  fullWidth?: boolean;
};

/**
 * A styled `SignupLink` component in the form of a button with tracking.
 */
export default function SignupCtaButton({
  translationKey = 'button__signup_cta',
  fullWidth = false,
}: SignupCtaButtonProps) {
  const t = useTranslate();

  return (
    <SignupLink
      dataTest="signup-cta-button"
      buttonType="primary"
      className={clsx('lg:px-10', fullWidth && 'w-full')}
      trackingTextId={translationKey}
    >
      {t(translationKey)}
      <span className="-mr-2">
        <SmallChevronIcon className="inline-block" aria-hidden />
      </span>
    </SignupLink>
  );
}
