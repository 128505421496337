import { PictureFieldsFragment, PictureType } from 'generated/graphql';
export type PlaceholderType = 'default' | 'default-wide' | 'artist' | 'group' | 'partner';

/**
 * Helper method that takes a list of Pictures and returns the
 * first one that matches the right PictureType. Typically used
 * to display the correct image type for a certain component.
 */
export function getPictureByType(
  pictures: PictureFieldsFragment[],
  type: PictureType,
): PictureFieldsFragment | undefined {
  // Runtime check to make sure `pictures` is not undefined because the API seems to return
  // undefined for some cases instead of an empty array
  if (pictures === undefined) return undefined;
  return pictures.find((picture) => picture.type === type);
}
