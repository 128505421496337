import { useRef } from 'react';
import { useReducedMotionVideo } from 'src/hooks/use-reduced-motion';

/** The video for the TV in the hero */
export function LandingPageTvVideo() {
  const ref = useRef<HTMLVideoElement>(null);

  // Don't autoplay video when the user refers reduced motion
  const reducedMotion = useReducedMotionVideo(ref);

  return (
    <div className="relative h-full transform-gpu bg-brand-stage" aria-hidden>
      <video
        // Suppress hydration warning because we always add `preload="none"`
        // during server side rendering, which can be removed on the client when
        // the reduced motion is off
        suppressHydrationWarning
        ref={ref}
        // Don't preload the video file on reduced motion, only show the poster image
        preload={reducedMotion ? 'none' : undefined}
        loop
        muted
        playsInline
        width="100%"
        height="100%"
        className="absolute inset-0 h-full object-cover"
        poster="/images/landing-page/video-poster.jpg"
      >
        <source src="https://cdn.stage-plus.com/web-landing-page/seasonal-2024.mp4" />
        <source src="https://cdn.stage-plus.com/web-landing-page/seasonal-2024.webm" />
      </video>
    </div>
  );
}

/** The video loop in the background */
export function LandingPageBackgroundVideo() {
  const ref = useRef<HTMLVideoElement>(null);

  // Don't autoplay video when the user refers reduced motion
  const reducedMotion = useReducedMotionVideo(ref);

  return (
    <div className="absolute inset-0 transform-gpu bg-mainBgBlueC2" aria-hidden data-test="video-background-container">
      <video
        // Suppress hydration warning because we always add `preload="none"`
        // during server side rendering, which can be removed on the client when
        // the reduced motion is off
        suppressHydrationWarning
        ref={ref}
        // Don't preload the video file on reduced motion, only show the poster image
        preload={reducedMotion ? 'none' : undefined}
        loop
        muted
        playsInline
        width="100%"
        height="100%"
        className="absolute inset-0 h-full object-cover fade-to-b"
        poster="https://res.cloudinary.com/dg-stage/image/upload/f_auto,q_auto/v1/web/onboarding-video-wide-placeholder_cccjvo"
      >
        <source src="https://cdn.stage-plus.com/web-landing-page/loop-wide.mp4" />
      </video>
    </div>
  );
}
