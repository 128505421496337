import Image, { type StaticImageData } from 'next/image';
import devicesComputerImage from 'public/images/landing-page/computer.svg';
import formats4kUltraHdImage from 'public/images/landing-page/formats-4k-ultra-hd.svg';
import formatsDolbyAtmosImage from 'public/images/landing-page/formats-dolby-atmos.svg';
import formatsLosslessImage from 'public/images/landing-page/formats-lossless.svg';
import devicesMobileTabletImage from 'public/images/landing-page/mobile-tablet.svg';
import devicesTvImage from 'public/images/landing-page/tv.svg';
import ContainerGrid from 'src/components/container-grid';
import useTranslate from 'src/hooks/use-translate';

// This is a block on a landing page that features the various platform types
// and platforms where Stage+ is available, as well as supported audio and video
// qualities.
export default function MarketingDevices() {
  const t = useTranslate();
  return (
    <div className="pb-28 pt-16 lg:pb-40" data-test="marketing-devices-module">
      <h2 className="dg-text-medium-1 mb-10 text-center lg:mb-12">{t('marketing_devices__headline')}</h2>
      <div className="mb-10 md:mb-[72px]">
        <ContainerGrid>
          <ul className="col-span-full grid gap-6 text-center md:grid-flow-col md:grid-cols-3 lg:gap-7">
            <li className="bg-highlightBlueC3 p-6 pb-8">
              <Image
                src={devicesComputerImage as StaticImageData}
                alt=""
                width="130"
                height="97"
                className="m-auto mb-6 w-[65px] md:w-[130px]"
              />
              <p className="mb-2.5 text-xl md:text-2xl">{t('marketing_devices__computer')}</p>
              <ul className="dg-text-medium-6 text-textTertiary">
                <li>{t('marketing_devices__computer_windows')}</li>
                <li>{t('marketing_devices__computer_macos')}</li>
                <li>{t('marketing_devices__computer_chrome')}</li>
              </ul>
            </li>
            <li className="bg-highlightBlueC3 p-6 pb-8">
              <Image
                src={devicesTvImage as StaticImageData}
                alt=""
                width="116"
                height="97"
                className="m-auto mb-6 w-[58px] md:w-[116px]"
              />
              <p className="mb-2.5 text-xl md:text-2xl">{t('marketing_devices__tv')}</p>
              <ul className="dg-text-medium-6 text-textTertiary">
                <li>{t('marketing_devices__tv_amazon')}</li>
                <li>{t('marketing_devices__tv_android')}</li>
                <li>{t('marketing_devices__tv_apple')}</li>
              </ul>
            </li>
            <li className="bg-highlightBlueC3 p-6 pb-8">
              <Image
                src={devicesMobileTabletImage as StaticImageData}
                alt=""
                width="119"
                height="97"
                className="m-auto mb-6 w-[60px] md:w-[119px]"
              />
              <p className="mb-2.5 text-xl md:text-2xl">{t('marketing_devices__mobile')}</p>
              <ul className="dg-text-medium-6 text-textTertiary">
                <li>{t('marketing_devices__mobile_iphone')}</li>
                <li>{t('marketing_devices__mobile_ipad')}</li>
                <li>{t('marketing_devices__mobile_browsers')}</li>
              </ul>
            </li>
          </ul>
        </ContainerGrid>
      </div>
      <h2 className="sr-only">{t('marketing_devices__audio_formats_headline')}</h2>
      <ul className="flex justify-center gap-6 md:gap-12">
        <li>
          <Image src={formats4kUltraHdImage as StaticImageData} alt="4K UltraHD" className="h-8 w-auto md:h-11" />
        </li>
        <li>
          <Image src={formatsDolbyAtmosImage as StaticImageData} alt="Dolby Atmos" className="h-8 w-auto md:h-11" />
        </li>
        <li>
          <Image src={formatsLosslessImage as StaticImageData} alt="Lossless" className="h-8 w-auto md:h-11" />
        </li>
      </ul>
    </div>
  );
}
