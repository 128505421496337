export type Duration = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const secondsToDuration = (seconds: number): Duration => {
  if (seconds <= 0) {
    return { days: 0, hours: 0, minutes: 0, seconds: 0 };
  }

  return {
    days: Math.floor(seconds / (3600 * 24)),
    hours: Math.floor((seconds / 3600) % 24),
    minutes: Math.floor((seconds / 60) % 60),
    seconds: Math.floor(seconds % 60),
  };
};

export const secondsToMinutes = (seconds: number): number => {
  // Round up to the nearest minute (e.g. 61 sec = 2 min)
  return Math.ceil(seconds / 60);
};

/**
 * Calculates the difference in seconds between two dates.
 * If the second date is not provided, it will default to the current date.
 * If the `fromDate` is later then the `toDate`, the result will be positive. Otherwise it will be negative.
 * @param fromDate - The date to calculate the difference from.
 * @param toDate - The date to calculate the difference to.
 * @returns Number of seconds (positive or negative)
 */
export const differenceInSeconds = (fromDate: Date, toDate: Date = new Date()): number => {
  return Math.floor((fromDate.valueOf() - toDate.valueOf()) / 1000);
};

/**
 * Calculates the difference in minutes between two dates.
 * @param fromDate - The date to calculate the difference from.
 * @param toDate - The date to calculate the difference to. Defaults to the current date.
 * @returns Number of minutes (positive or negative)
 */
export const differenceInMinutes = (fromDate: Date, toDate: Date = new Date()): number => {
  return Math.floor((fromDate.valueOf() - toDate.valueOf()) / (1000 * 60));
};

/**
 * Calculates the difference in days between two dates.
 * If the second date is not provided, it will default to the current date.
 * If the `fromDate` is later then the `toDate`, the result will be positive. Otherwise it will be negative.
 * @param fromDate - The date to calculate the difference from.
 * @param toDate - The date to calculate the difference to.
 * @returns Number of days (positive or negative)
 */
export const differenceInDays = (fromDate: Date, toDate: Date = new Date()): number => {
  return Math.floor((fromDate.valueOf() - toDate.valueOf()) / (1000 * 60 * 60 * 24));
};

/**
 * Calculates the difference in calendar days between two dates.
 * @param fromDate – The date to calculate the difference from.
 * @param toDate – The date to calculate the difference to. Defaults to the current date.
 * @returns Number of calendar days (positive or negative)
 */
export const differenceInCalendarDays = (fromDate: Date, toDate: Date = new Date()): number => {
  // Normalize dates to UTC midnight
  const utcFromDate = Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
  const utcToDate = Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate());

  // Calculate the difference in milliseconds
  const differenceMs = utcFromDate - utcToDate;

  // Convert milliseconds to days, rounding towards zero
  return Math.trunc(differenceMs / (1000 * 60 * 60 * 24));
};
