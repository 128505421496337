import { RefObject, SVGProps, useCallback } from 'react';
import clsx from 'clsx';
import { ButtonIconOnly } from 'src/components/buttons/icon-button';
import styles from 'src/components/scroll-down-hint.module.css';
import useDidScrollBeyond from 'src/hooks/use-scroll-beyond';
import useTranslate from 'src/hooks/use-translate';

type ScrollDownHintProps = {
  refToScrollTo: RefObject<HTMLDivElement>;
  hideWhenScrolledBeyond?: number;
};

/**
 * An SVG icon that shows it is possible to scroll down to the next section of the page.
 */
function ScrollDownIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" width="24" height="24" {...props}>
      <path
        d="M27.3282 15.3984L16.5 25.3516L5.72659 15.4531C5.56253 15.2891 5.34378 15.1797 5.17972 15.1797C4.90628 15.1797 4.68753 15.2891 4.52347 15.5078C4.19534 15.8359 4.19534 16.3828 4.57815 16.7109L15.9532 27.2109C16.2813 27.5391 16.7735 27.5391 17.1016 27.2109L28.4766 16.7109C28.8594 16.3828 28.8594 15.8359 28.5313 15.5078C28.2032 15.0703 27.6563 15.0703 27.3282 15.3984ZM15.9532 16.6563C16.2813 16.9844 16.7735 16.9844 17.1016 16.6563L28.4766 6.15625C28.8594 5.82813 28.8594 5.28125 28.5313 4.95313C28.2032 4.57031 27.6563 4.57031 27.3282 4.89844L16.5547 14.7969L5.72659 4.89844C5.56253 4.73438 5.34378 4.67969 5.17972 4.67969C4.90628 4.67969 4.68753 4.73438 4.52347 4.95313C4.19534 5.28125 4.19534 5.82812 4.57815 6.15625L15.9532 16.6563Z"
        fill="currentColor"
      />
    </svg>
  );
}

/**
 * A component that displays a hint that the user can scroll down to see more content.
 *
 */
const ScrollDownHint = ({ refToScrollTo, hideWhenScrolledBeyond = 100 }: ScrollDownHintProps) => {
  const t = useTranslate();
  // check when the user has scrolled beyond the threshold
  const displayBoundaryReached = useDidScrollBeyond(hideWhenScrolledBeyond);
  //  what happens when the user clicks the button
  const clickHandler = useCallback(() => {
    const nextBlock = refToScrollTo.current;
    nextBlock?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [refToScrollTo]);

  return (
    <div className={clsx('transform-gpu transition-opacity duration-300', displayBoundaryReached && 'opacity-0')}>
      <div className={styles.signalAnimation}>
        <ButtonIconOnly
          title={t('accessibility__scroll_down_hint')}
          onClick={clickHandler}
          icon={<ScrollDownIcon className="size-8" />}
          dataTest="scroll-down-button"
          className="size-8"
        ></ButtonIconOnly>
      </div>
    </div>
  );
};

export default ScrollDownHint;
