import { HTMLProps, PropsWithChildren } from 'react';

type TeaserProps = Omit<PropsWithChildren<HTMLProps<HTMLDivElement>>, 'className'>;

/**
 * A wrapper element for a teaser. Typically contains a teaser image, title, and meta information.
 *
 * @example
 * ```tsx
 * <Teaser>
 *  <TeaserImage src={src} alt={alt} variant="round" />
 *  <TeaserTitle primary={title} secondary={subtitle} />
 *  <TeaserMeta>{durationInMinutes}</TeaserMeta>
 * </Teaser>

 */
export function Teaser(props: TeaserProps) {
  return <div className="group relative" {...props} />;
}
