import { SupportedLocale } from 'src/types';
import { isCurrencyCode, zeroDecimalCurrencies } from 'src/utilities/currency-code-helpers';
import { defaultLocale } from 'src/utilities/i18n-helpers';

export type DateFormatStyle = 'mediumTime' | 'short' | 'shortTime' | 'monthYear';
// Internationalization supports both language language-region codes (e.g. 'en', 'en-US', etc.)
export type IntlLocale = SupportedLocale | `${SupportedLocale}-${string}`;

/**
 * Takes a locale and returns a supported DateTime locale
 * Makes sure that we only use a subset of language-region combinations that we officially support
 *
 * @param locale Locale to check (e.g. 'en', 'en-US', 'en-FR')
 * @returns Supported locale ('en-US', 'en-GB', 'ja-JP' and 'de-DE' are supported)
 */
function getNormalizedLocale(locale: IntlLocale = defaultLocale): IntlLocale {
  const [language, region] = locale.split('-');

  switch (language) {
    case 'en': {
      return region === 'US' ? 'en-US' : 'en-GB';
    }
    case 'de': {
      return 'de-DE';
    }
    case 'ja': {
      return 'ja-JP';
    }
    default: {
      return 'en-US';
    }
  }
}

/**
 * Takes a locale and returns a list of date time format options
 *
 * @param locale Locale to check (e.g. 'en-US', 'ja-JP')
 * @returns Date time format options
 */
function getDateTimeFormatOptions(
  locale: IntlLocale = defaultLocale,
): Record<DateFormatStyle, Intl.DateTimeFormatOptions> {
  // Japanese uses slightly different date formats than the other ones we support
  if (locale.startsWith('ja')) {
    return {
      // medium date with time e.g. '2022年2月17日 15:36'
      mediumTime: { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' },
      // short date e.g. '2022年2月17日'
      short: { year: 'numeric', month: 'long', day: 'numeric' },
      // short date with time e.g. '2022年2月17日 15:36'
      shortTime: { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
      // month and year only e.g. '2022年2月'
      monthYear: { month: 'long', year: 'numeric' },
    };
  }
  return {
    // medium date with time e.g. 'Feb 17, 2022, 15:36'
    mediumTime: { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false },
    // short date e.g. '02/17/2022'
    short: { year: 'numeric', month: '2-digit', day: '2-digit' },
    // short date with time e.g. '02/17/2022, 15:36'
    shortTime: { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false },
    // month and year only e.g. 'April 2022'
    monthYear: { month: 'long', year: 'numeric' },
  };
}

/**
 * Takes an array of strings and returns string with a localized separator between each item.
 * We don’t use the Intl.ListFormat API because it’s not supported in recent Safari versions and we don’t want to
 * polyfill it for our limited use case.
 * @example
 * ```ts
 * const items = ['a', 'b', 'c'];
 * const list = listFormat(items);
 * // 'a, b, c'
 * const japaneseList = listFormat(items, { locale: 'ja' });
 * // 'a、b、c'
 */
export function listFormat(array: string[], options: { locale?: IntlLocale } = {}): string {
  const normalizedLocale = getNormalizedLocale(options.locale);
  if (normalizedLocale.startsWith('ja')) return array.join('、');
  return array.join(', ');
}

/**
 * Converts a number to a currency string
 * @param amount Value amount to convert (should always an integer in the smallest unit of the currency)
 * @param currency Type of currency to convert to
 * @param locale Locale to use for currency formatting
 * @returns Locale formatted currency string
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 *
 * @example
 * ```
 * const amount = 12345;
 * const currency = 'EUR';
 * const locale = 'de';
 *
 * const currencyString = formatCurrency(amount, currency, locale);
 * // => '123,45 €'
 */
export function currencyFormat(amount: number, currency: string, locale: IntlLocale = defaultLocale): string {
  if (!Number.isInteger(amount)) throw new Error('Amount must be an integer');
  if (!isCurrencyCode(currency)) throw new Error('Invalid currency code');
  const decimalAmount = new Set(zeroDecimalCurrencies).has(currency) ? amount : amount / 100;
  return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(decimalAmount);
}

/**
 * Converts a date to a localized date string
 * @param date The date to convert
 * @param options.style The style of date to format (default 'shortTime')
 * @param options.locale Locale to use for currency formatting (default 'en')
 * @returns Locale formatted currency string
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 *
 * @example
 * ```
 * const date = new Date();
 * const formattedDate = dateFormat(date, 'short');
 * // formattedDate is '02/17/2022'
 */
export function dateFormat(locale: IntlLocale, style: DateFormatStyle = 'shortTime'): Intl.DateTimeFormat {
  const normalizedLocale = getNormalizedLocale(locale);
  const dateTimeFormatOptions = getDateTimeFormatOptions(normalizedLocale);
  return Intl.DateTimeFormat(normalizedLocale, dateTimeFormatOptions[style]);
}

/**
 * Capitalizes the first character of a given string
 * @param string The string to capitalize
 * @param locale Locale to use for capitalization (default 'en')
 * @returns Capitalized string
 *
 * @example
 * ```ts
 * const string = 'hello world';
 * const capitalizedString = capitalizeFirstCharacter(string);
 * // capitalizedString is 'Hello world'
 */
export function capitalizeFirstCharacter(string: string, locale: IntlLocale = defaultLocale): string {
  const normalizedLocale = getNormalizedLocale(locale);
  return string.charAt(0).toLocaleUpperCase(normalizedLocale) + string.slice(1);
}
